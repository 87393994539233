import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpserviceService: HttpserviceService) { }

  updateDataById(data: any, order_id: number){
    return this.httpserviceService.post(data, "order_status/" + order_id +"/update");
  }

  createOrder(data: any){
    return this.httpserviceService.post(data, "order");
  }
}
