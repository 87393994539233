import { Injectable } from '@angular/core';
import { HttpserviceService } from './httpservice.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private httpserviceService: HttpserviceService) { }

  getClients(params: any = ""){
    return this.httpserviceService.get("clients" + params);
  }

  updateClientById(data: any, client_id: number){
    return this.httpserviceService.post(data, "client/" + client_id);
  }

  getPromosByClient(client_id: number){
    return this.httpserviceService.get("client/promos/" + client_id);
  }

  applyPromoByClient(client_id: number, promo_id: number){
    return this.httpserviceService.post({}, "client/apply_promo/" + client_id + "/" + promo_id);
  }

  createClient(data: any){
    return this.httpserviceService.post(data, "client");
  }
}
